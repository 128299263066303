.nav{
   margin-top: 4px !important;
   background:"green" !important;
 
}// working

.nav-item {
  margin: 0px 0px !important; /// menu header
  font-size:14px;
  // color: #00FF2A;    ///  footer color change
  .nav-link {
    display: flex;
    align-items: center;
    //color: #FFFF00 !important;  ///side menu Font Color
    &.active {
      color: #fff;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
 
}
.text-white {
    font-size: 18px !important;
}
.sidebar .nav a {
  transition: all 150ms ease-in;
  font-size:12;
}

.text-secondary {
   color: #696969 !important; //search icon  color
}

.card-header {
  text-transform: uppercase;
  
}

.badge:empty {
  display: inline-block;
}
.cr-content {
///  color: #FFFF00 ;
}
.vertical-timeline {
    padding: 0 !important;
  
}

.vertical-timeline-element {
    
    margin: 1em 0 !important;
}

.vertical-timeline-element-title {
  .h5{
    font-size: 1rem !important;

  }
    
}
/* CUSTOM LINE COLOR */
/* The line */
.vertical-timeline.vertical-timeline-custom-line::before {
  background: #FFFF2A;
}

div.ex1 {
  overflow-y: scroll !important;
  //overflow-y: auto !important;
  // position: 'fixed';
  //  flex: 1;
  //background: #aaa;
  //overflow-y: scroll;
}

@media print {
  .pagebreak { page-break-after: always; } /* page-break-after works, as well */
}

